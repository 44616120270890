
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import eventBus from "@/core/services/eventBus";
import authenticationService from "@/core/services/AuthenticationService";


import CollectionSettings from "./CollectionSettings.vue";
import CollectionAward from './CollectionAward.vue';

export default {
  components: {CollectionSettings, CollectionAward},
  mounted() {
    setCurrentPageTitle("Book Collection");
  },
  beforeUnmount() {
    eventBus.$off('readingComplete');
  },
  data() {
    return {
      collectionTitle: null,
      frameBg: null,
      selectedSchoolYear: null,
      currentFrame: null
    }
  },
  methods: {
    updateCollectionName(name) {
      this.collectionTitle = name
    },
    updateFrameBg(color) {
      this.framebg = color
    },
    updateSchoolYear(year) {
      this.selectedSchoolYear = year
    },
    updateFrame(frame) {
      this.currentFrame = frame
    },
    displayUpdatedSettings(e) {
      console.log(e);
      this.collectionTitle = e.title,
      this.frameBg = e.frameBg,
      this.currentFrame = e.frame
    }
  },
}
