import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "book-cover" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.oneElementArrayToFixChromeBug, (cover) => {
      return (_openBlock(), _createElementBlock("div", {
        key: cover,
        class: "cover-image-wrapper"
      }, [
        _createElementVNode("img", {
          src: $options.coverImageURL,
          class: "cover-image-default add-shadow",
          alt: 'Book cover ' + $options.levelCoverImage,
          onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onError && $options.onError(...args))),
          draggable: "false"
        }, null, 40, _hoisted_2),
        ($data.showEmblem)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "emblem_wrapper",
              style: _normalizeStyle({backgroundColor: $options.getBgColor($props.book)})
            }, [
              ($options.getBgColor($props.book) === '#000000')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "emblem",
                    src: require('/public/media/logos/arc-emblem-white.svg'),
                    alt: "arc emblem"
                  }, null, 8, _hoisted_3))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    class: "emblem",
                    src: require('/public/media/logos/arc-emblem.svg'),
                    alt: "arc emblem"
                  }, null, 8, _hoisted_4))
            ], 4))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}