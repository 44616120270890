<template>
      <div class='award-stats'>
        <div class='stat-group'>
          <p class='stat-value'>{{stepsTotal/4}}</p>
          <p class='stat-text'>{{$t('pages.collection.collectionStats.hoursRead')}}</p>
        </div>
        <div class='stat-group'>
          <p class='stat-value' id="stat-number-of-completed-books">{{recentlyCompleted.length}}</p>
          <p class='stat-text'>{{$t('pages.collection.collectionStats.completedBooks')}}</p>
        </div>
        <div class='stat-group'>
          <p class='stat-value'>{{stepsTotal}}</p>
          <p class='stat-text'>{{$t('pages.collection.collectionStats.totalSteps')}}</p>
        </div>
        <div class='stat-group'>
          <p class='stat-value'>{{streak.longest}}</p>
          <p class='stat-text'>{{$t('pages.collection.collectionStats.longestStreak')}}</p>
        </div>
      </div>
</template>

<script>
import PersistentStorage from "@/core/store/PersistentStorage";
import logService from "@/app/services/LogService";


    export default {
        props: ['recentlyCompleted'],
        computed: {
            stepsTotal() {
                let total = 0;
                (PersistentStorage.appData.log || []).forEach(book => {
                    (book.log_entries || []).forEach(entry => {
                    total += entry.steps;
                    });
                });
                return total;
            },
            streak() {
                return logService.getReadingStreak();
            },
        }
    }
</script>

<style lang="scss">
    .award-stats {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 90%;
      margin: 2em auto;
      .stat-group {
        display: flex;
        width: 125px;
        flex-direction: column;
        align-items: center;
        .stat-value {
          font-size: 1.8em;
          font-weight: bold;
        }
      }
    }

    @media screen and (max-width: 550px ) {
      .award-stats {
        .stat-group {
          width: 50%;
        }
      }
    }
</style>