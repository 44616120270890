import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, vModelRadio as _vModelRadio } from "vue"

const _hoisted_1 = { class: "collection-grid-wrapper" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-6 card p-5 border border-3 rounded" }
const _hoisted_4 = { for: "name-your-collection" }
const _hoisted_5 = { class: "grid-cell-header" }
const _hoisted_6 = { class: "col-6 card p-5 border border-3 rounded" }
const _hoisted_7 = { for: "bg-colors" }
const _hoisted_8 = { class: "grid-cell-header" }
const _hoisted_9 = {
  class: "bg-colors d-flex",
  id: "bg-colors"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  style: {position: 'absolute'}
}
const _hoisted_12 = {
  key: 1,
  class: "default-color-text position-absolute"
}
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-12 card p-5 border border-3 rounded" }
const _hoisted_15 = { for: "frame-options" }
const _hoisted_16 = { class: "grid-cell-header" }
const _hoisted_17 = {
  class: "frame-options d-flex flex-wrap justify-content-around",
  id: "frame-options"
}
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["for"]
const _hoisted_20 = ["src", "alt"]
const _hoisted_21 = ["name", "id", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('pages.collection.grid.nameCollection')), 1)
        ]),
        _withDirectives(_createElementVNode("input", {
          maxlength: "40",
          class: "form-control",
          id: "name-your-collection",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.collectionTitle) = $event)),
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.updateCollectionName && $options.updateCollectionName(...args)))
        }, null, 544), [
          [_vModelText, $data.collectionTitle]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, [
          _createElementVNode("h1", _hoisted_8, _toDisplayString(_ctx.$t('pages.collection.grid.chooseBg')), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getFrameBgOptions(), (color, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: color,
              class: "color-block mx-5 position-relative d-flex justify-content-center align-items-center rounded",
              style: _normalizeStyle({backgroundColor: color}),
              onClick: ($event: any) => ($options.updateFrameBg(color))
            }, [
              (color === $data.frameBg)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_inline_svg, {
                      src: `/media/icons/check-icon${ color == '#fff' ? '-black' : ''}.svg`,
                      class: "check-icon",
                      alt: "Check Icon"
                    }, null, 8, ["src"])
                  ]))
                : _createCommentVNode("", true),
              (index == 0)
                ? (_openBlock(), _createElementBlock("small", _hoisted_12, _toDisplayString(_ctx.$t('pages.collection.grid.default')), 1))
                : _createCommentVNode("", true)
            ], 12, _hoisted_10))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("label", _hoisted_15, [
          _createElementVNode("h1", _hoisted_16, _toDisplayString(_ctx.$t('pages.collection.grid.selectFrame')), 1)
        ]),
        _createElementVNode("div", _hoisted_17, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getFrameOptions(), (frame) => {
            return (_openBlock(), _createElementBlock("div", {
              key: frame,
              class: "collection-frame-option d-flex flex-column align-items-center m-3",
              onClick: ($event: any) => ($options.updateFrame(frame))
            }, [
              _createElementVNode("label", {
                for: 'frame-options'+frame
              }, [
                _createElementVNode("img", {
                  src: '/media/frames/frame-' + frame + '.png',
                  class: "mb-5",
                  alt: 'Frame Option ' + frame
                }, null, 8, _hoisted_20)
              ], 8, _hoisted_19),
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                name: frame,
                id: 'frame-options'+frame,
                value: frame,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.currentFrame) = $event))
              }, null, 8, _hoisted_21), [
                [_vModelRadio, $data.currentFrame]
              ])
            ], 8, _hoisted_18))
          }), 128))
        ])
      ])
    ])
  ]))
}