
import eventBus from "@/core/services/eventBus";
import authenticationService from "@/core/services/AuthenticationService";

export default {
  mounted() {
    let currentTitle = authenticationService.getSetting("collectionName");
    this.collectionTitle = !currentTitle ? "My First Collection" : currentTitle

    let frameBg = authenticationService.getSetting("frameBg");
    this.frameBg = !frameBg ? '#fff' : frameBg;

    let currentFrame = authenticationService.getSetting("frame");
    this.currentFrame = !currentFrame ? 1 : currentFrame

    this.onChange();
  },
  beforeUnmount() {
    eventBus.$off('readingComplete');
  },
  data() {
    return {
      collectionTitle: null,
      frameBg: null,
      currentFrame: null
    }
  },
  methods: {
    /* Get */
    getFrameBgOptions() {
      let bgOptions = ['#fff', '#000']
      return bgOptions;
    },
    getFrameOptions() {
      let frameOptions = [1, 2,];
      return frameOptions;
    },

    /* Set */
    updateCollectionName() {
      authenticationService.changeSetting("collectionName", this.collectionTitle);
      this.onChange();
    },
    updateFrameBg(color) {
      this.frameBg = color
      authenticationService.changeSetting("frameBg", color);
      this.onChange();
    },
    updateFrame(frame) {
      this.currentFrame = frame
      authenticationService.changeSetting("frame", this.currentFrame);
      this.onChange();
    },

    /* Update Award */
    onChange() {
      let settings = {
        title: this.collectionTitle,
        frameBg: this.frameBg,
        frame: this.currentFrame
      }
      this.$emit('updateSettings', settings )
    },
  },
}
