<template>
  <div class="grid-container">
      <div class="box" v-for="(book, index) in recentlyCompleted" :key="book" :style="{gridRow: getRow(book,  getImageDimensions(book).imageHeight), height: getImageDimensions(book).height }" ref="box">
            <div class="grid-item-content" v-if="book.book">
                <div class="image-group">

                    <!-- BS BOOKS WITH COVER IMAGE -->
                    <img  :src="coverImageURL(book)" @error="errorResponse($event, index)" :alt="book.title + 'cover'"/>

                    <!-- BS BOOKS WITH NO COVER AVAILABLE -->
                    <div class="book-details " :style="{display: 'none', border: `solid 4px ${getReadingLevel(book)}`}" ref="altDetails">
                        <h1 class="book-title">{{book.title}}</h1>
                        <h2 class="book-author">{{book.author}}</h2>
                    </div>

                </div>
            </div>

            <!-- MANUALLY ENTERED BOOKS -->
            <div v-else-if="book.book == null" class="book-details" ref="altDetails" :style="{border: `solid 4px ${getReadingLevel(book)}`}" >
                <h1 class="book-title">{{book.title}}</h1>
                <h2 class="book-author">{{book.author}}</h2>
            </div>

            <!-- ARC EMBLEM -->
            <div class="emblem_wrapper" :style="{backgroundColor: getReadingLevel(book)}">
                <img v-if="getReadingLevel(book) === '#000000'" class="emblem" :src="require('/public/media/logos/arc-emblem-white.svg')" alt="arc emblem"/>
                <img v-else class="emblem" :src="require('/public/media/logos/arc-emblem.svg')" alt="arc emblem"/>
            </div>
      </div>
  </div>
</template>

<script>
import bookService from "@/app/services/bookService";
import BookCover from "@/app/components/BookCover.vue";


    export default {
        props:['recentlyCompleted'],
        components: {BookCover},
        methods: {
            coverImageURL(book) {
                return bookService.coverImage(book);
            },
            errorResponse(e, index){
                e.target.style.display = "none"
                this.$refs.altDetails[index].style.display = 'block'
            },
            getReadingLevel(book) {
                if (book.book) {
                    let level = book.book.reading_level_irla
                    let details = bookService.getBorderColor(level.toLowerCase());
                    return details.color
                } else {
                    return '#888'
                }
            },
            getRow(book, imageHeight) {
                // Manually Entered Books 
                if(book.book == null) {
                    return 'span 12';
                }
                // BS Books w/o cover
                else if (book.book !== null && imageHeight === 0) {
                    return 'span 10'
                }
                // BS Books with Cover
                else {
                    let spans = Math.ceil(imageHeight / 10);
                    return `span ${spans}`
                }
            },
            getImageDimensions(book) {
                let imageHeight;
                let height;
                if(book.book) {
                    let cover = new Image();
                    cover.src = bookService.coverImage(book);
                    let width = cover.width;
                    let ratio = 175/width;
                    imageHeight = cover.height > 0 ? cover.height * ratio : 0;
                    height = cover.height > 0 ? cover.height * ratio + 'px': '100%';
                } else if (!book.book) {
                    imageHeight = 0;
                    height = '100%';
                }
                return {
                    imageHeight: imageHeight,
                    height: height,
                }
            }
        },
    }
</script>

<style lang="scss">
    .grid-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
        grid-template-rows: auto 1fr;
        grid-auto-flow: dense;
        grid-gap: 10px;
        justify-content: center;
        // ------------------------- Book Block ------------------------- 
        .box {
            position: relative;
            padding: 0.1em;
            overflow: hidden;
            max-height: 250px;
            .grid-item-content {
                height: 100%;
                width: 100%;
                .image-group {
                    width: fit-content;
                    width: 100%;  
                    img {
                        width: 100%;
                    }
                }
            }
            // ------------------------- Text Details ------------------------- 
            .book-details {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0.5em;
                text-align: left;
                width: 100%;
                height: 100%;
                padding: 1em;
                position: relative;
                .book-title {
                    font-weight: bold;
                    font-size: 16px;
                    margin-bottom: 0.25em;
                }
                .book-author {
                    font-size: 12px;
                    font-weight: normal;
                    margin-bottom: 2.5em;
                }
                
            }
            // ------------------------- Emblem ------------------------- 
            .emblem_wrapper {
                    height: 30px;
                    width: 40px;
                    position: absolute;
                    left: 1px;
                    bottom: 0.5em;
                    display: flex;
                    background-color: rgba(153, 153, 153, 0.75);
                    .emblem {
                        height: 25px;
                        width: 25px;
                        margin: auto;
                    }
                }
        }

    }
</style>