import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-collection" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_collection_settings = _resolveComponent("collection-settings")!
  const _component_collection_award = _resolveComponent("collection-award")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_collection_settings, { onUpdateSettings: $options.displayUpdatedSettings }, null, 8, ["onUpdateSettings"]),
    _createVNode(_component_collection_award, {
      currentFrame: $data.currentFrame,
      currentBg: $data.frameBg,
      collectionTitle: $data.collectionTitle,
      selectedSchoolYear: $data.selectedSchoolYear
    }, null, 8, ["currentFrame", "currentBg", "collectionTitle", "selectedSchoolYear"])
  ]))
}