<template>
    <div class='collection-award' :style="{color: currentBg === '#000' ? '#fff' : '#000', borderImage: `url('/media/frames/full-frame-${currentFrame}.svg') 125 135 140 115 stretch stretch`}">
      <div id="collection-image">
        <div class='collection-bg' :style="{backgroundColor: currentBg}">
          <div class="award-content">
              <div class='award-titles'>
                <h1 class='award-title'>{{collectionTitle}}</h1>
                <!-- <h2 class='award-subtitle'>{{$t('pages.collection.collectionAward.schoolYear')}} {{selectedSchoolYear}}</h2> -->
              </div>
              <collection-stats :recentlyCompleted="recentlyCompleted" class='collection-stats'/>
              <completed-books :recentlyCompleted="recentlyCompleted" class='completed-books'/>
            <collection-footer class='award-footer'/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import PersistentStorage from "@/core/store/PersistentStorage";

import CollectionStats from "./CollectionStats.vue";
import CompletedBooks from './CompletedBooks.vue';
import CollectionFooter from './CollectionFooter.vue';


  export default {
    props: ['currentFrame', 'currentBg', 'collectionTitle', 'selectedSchoolYear'],
    components: {CollectionStats, CompletedBooks, CollectionFooter},
    computed: {
      recentlyCompleted() {
          return (PersistentStorage.appData.log || []).filter(book => book.status == 'Complete');
      },
    }
  }
</script>

<style lang="scss" scoped>
    .collection-award {
    text-align: center;
    background-size: cover;
    overflow: hidden;
    position: relative;
    border-style: inset;
    border-width: 60px;
    #collection-image {
      overflow: hidden;
    }
    .collection-bg {
      height: 100%;
      width: 100%;
      position: relative;
      z-index: -1;
      .award-content {
        padding: 4em;
        .award-titles {
          .award-title {
            font-size: 2.5em;
            font-weight: bold;
            color: #B7822F;
          }
          .award-subtitle {
            color: #B7822F;
          }
        }
        .completed-books {
          min-height: 750px;
        }
        .award-footer {
          position: relative;
        }
      }
    }
  }


  @media screen and (max-width: 990px ) {
    .collection-bg {
      .completed-books {
        width: 75%;
        margin: 0 auto;
      }
    }
  }

  @media print {
    .collection-award {
      background-image: none!important;
      .completed-books {
        min-height: auto!important;
      }
    }
  }
</style>