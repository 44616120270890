
import bookService from "@/app/services/bookService";

export default {
  props: ['book', 'bookLevel'],
  data() {
    return {
      showEmblem: true,
    }
  },
  computed: {
    /* AR-120: Fix missing cover images
     * This next method is a hack to work around a bug in some browsers (Chrome, Firefox):
     * https://stackoverflow.com/questions/10366867/object-tag-doesnt-refresh-when-its-data-attribute-is-changed-in-chrome
     *
     * To summarize:
     * Once an <object> tag gets a 404 on its data URL, it stops ever loading images again - even if the data attribute
     * is later changed to a valid URL. So if the user clicks on a book without a cover, then clicks on one that does
     * have a cover, that new cover won't load.
     *
     * To work around that bug, this component uses a v-for directive to iterate over a computed property. Since the
     * computed array changes when the 'book' property is changed, the old rendered <object> tag gets removed, and a
     * new one gets re-inserted by v-for. This allows images to always load properly.
     */
    oneElementArrayToFixChromeBug() {
      return [
        this.book
      ]
    },
    coverImageURL() {
      return bookService.coverImage(this.book);
    },
    levelCoverImage() {
      switch (this.bookLevel) {
        default:
          return ''
        case 'lam':
        case 'rtm':
        case 'icp':
          return '-rtm'
        case '1y':
        case '2y':
        case '3y':
          return '-y'
        case '1g':
        case '2g':
          return '-g'
        case '1b':
        case '2b':
          return '-b'
        case '1r':
        case '2r':
          return '-r'
        case 'wt':
        case 'wt-alt':
          return '-w'
        case "bk":
          return '-bk'
        case "or":
          return '-o'
        case "pu":
          return '-p'
        case "br":
        case "1br":
        case "2br":
          return '-br'
        case "si":
        case "1si":
        case "pl":
          return '-si'
        case "gl":
        case "1gl":
        case "2gl":
        case "oro":
          return "-gl"
      }
    }
  },
  methods: {
    onError(e) {
      if (this.bookLevel) {
        e.target.src = `/media/books/no-cover${this.levelCoverImage}.svg`
        e.target.classList.remove('add-shadow')
        this.showEmblem = false;
      } else {
        e.target.src = '/media/books/no-cover.jpg'
      }
    },
    getBgColor(book) {
      if (book?.borderColor) {
        return '#' + book.borderColor;
      }
      else if(book?.book) {
        let level = bookService.getBorderColor(book.book.reading_level_irla.toLowerCase())
        return level.color;
      }
      else if (book?.reading_level_irla) {
        let level = bookService.getBorderColor(book.reading_level_irla.toLowerCase())
        return level.color;
      }
      else {
        return 'rgba(153, 153, 153, 0.75);'
      }
  },
  }
}
