<template>
    <div class='award-footer'>
        <div class='award-footer-line' id="award-grid-1">
          <p v-if="displayName && displayName.toLowerCase() !== fullName.toLowerCase()">{{displayName}} <span :style="{fontWeight: 400, fontSize: '14px'}">({{fullName}})</span></p>
          <p v-else-if="!displayName">{{fullName}}</p>
          <p v-else-if="displayName.toLowerCase() === fullName.toLowerCase()">{{fullName}}</p>
          <div class='horizontal-line'></div>
          <p class='line-text'>{{$t('pages.collection.collectionFooter.recipient')}}</p>
        </div>
        <div class='award-footer-line' id="award-grid-2">
          <p>{{getDate()}}</p>
          <div class='horizontal-line' :style="{width: '175px'}"></div>
          <p class='line-text'>{{$t('pages.collection.collectionFooter.date')}}</p>
        </div>
        <img src="/media/frames/arc-ribbon.png" id="award-grid-3" alt="ARC Award Ribbon"/>
    </div>
</template>

<script>
    import authenticationService from "@/core/services/AuthenticationService";
    import moment from "moment-with-locales-es6";

    export default {
        data() {
            return {}
        },
        computed: {
            displayName() {
                let user = authenticationService.currentUser();
                if (user && user.profile) {
                    return user.profile.display_name;
                }
                return '';
            },
            fullName() {
                let user = authenticationService.currentUser();
                if (user && user.fullName) {
                    return user.fullName
                }
                return '';
            },
        },
        methods: {
            getDate() {
                return moment().locale(this.$i18n.locale).format('MMMM DD, YYYY');
            }
        }
    }
</script>

<style lang="scss">
    .award-footer {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
        .award-footer-line {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 1.4em;
            font-weight: bold;
        .horizontal-line {
            height: 2px;
            width: 250px;
            background-color: #DAB560;
        }
        .line-text {
            color: #DAB560;
            font-size: 0.8em;
        }
    }
  }

  @media screen and (max-width: 601px) {
    .award-footer {
        display: block;
        .award-footer-line {
            overflow: hidden;
        }
        img {
            position: relative!important;
        }
    }
  }

  @media print {
    .award-footer {
        display: grid;
        grid-template-rows: 2;
        grid-template-columns: 2;
        #award-grid-1 {
            grid-row: 1/2;
            grid-column: 1/2;
        }
        #award-grid-2 {
            grid-row: 2/3;
            grid-column: 1/2;
        }
        #award-grid-3 {
            grid-row: 1/3;
            grid-column: 2/3;
        }
    }
  }
</style>